.dashboard-card .cpgl-steptitle {
  padding-top: 1em;
  margin-top: 0.5em;
  width: auto;
}

.dashboard-step-wrap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 98%;
  margin: 2em auto;
}

.dashboard-map {
  position: relative;
}

.dashboard-map > .maplayerscard-wrap {
  width: auto;
  float: right;
}

.dashboard-map > .maplayerscard-wrap > * {
  width: auto;
  background-color: var(--color-2);
}

#maplayersbutton {
  color: var(--color-light);
}

.practice-list {
  display: flex;
  gap: 1em;
  flex-direction: column;
}
.practice-list > * {
  flex: 1;
}
@media only screen and (min-width: 1100px) {
  .practice-list {
    flex-direction: row;
  }
}
@media only screen and (min-width: 750px) {
  .dashboard-step-wrap {
    width: 90%;
    max-width: 1200px;
  }
  .dashboard-map > .maplayerscard-wrap {
    position: absolute;
    max-width: 300px;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 2;
    background-color: rgb(255 255 255 / 0.4);
    border: 3.5px solid rgb(255 255 255 / 0.1);
    border-radius: 0.5em;
  }

  .dashboard-map > .maplayerscard-wrap > * {
    background-color: var(--ol-background-color);
  }

  #maplayersbutton {
    color: var(--color-1);
  }
}

.global-step-integer {
  position: absolute;
  background-color: #4f605b;
  border-radius: 50%;
  color: white;
  width: 2em;
  height: 2em;
  display: grid;
  place-items: center;
  float: left;
}

/* @media only screen and (min-width: var(--breakpoint-1)) { */
@media only screen and (min-width: 900px) {
  .dashboard-step-wrap {
    display: grid;
    gap: 1em;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .dashboard-step-wrap > div:nth-child(1) {
    grid-column: 1/4;
    grid-row: 1;
  }

  .dashboard-step-wrap > div:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
  }

  .dashboard-step-wrap > div:nth-child(3) {
    grid-column: 2/4;
    grid-row: 2;
  }

  .dashboard-step-wrap > div:nth-child(4) {
    grid-column: 1/4;
    grid-row: 3;
  }

  .dashboard-step-wrap > div:nth-child(5) {
    grid-column: 1/4;
    grid-row: 4;
  }
  .dashboard-step-wrap > div:nth-child(6) {
    grid-column: 1/4;
    grid-row: 5;
  }
  .dashboard-step-wrap > div:nth-child(7) {
    grid-column: 1/4;
    grid-row: 6;
  }
}

@media only screen and (min-width: 1930px) {
  .practice-list {
    flex-direction: column;
  }
  .dashboard-step-wrap {
    max-width: 1600px;
    display: grid;
    gap: 1em;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .dashboard-step-wrap > div:nth-child(1) {
    grid-column: 1/3;
    grid-row: 1/3;
  }

  .dashboard-step-wrap > div:nth-child(2) {
    grid-column: 3;
    grid-row: 1;
  }

  .dashboard-step-wrap > div:nth-child(3) {
    grid-column: 3;
    grid-row: 2;
  }

  .dashboard-step-wrap > div:nth-child(4) {
    grid-column: 1;
    grid-row: 3/5;
  }

  .dashboard-step-wrap > div:nth-child(5) {
    grid-column: 2/4;
    grid-row: 3;
  }

  .dashboard-step-wrap > div:nth-child(6) {
    grid-column: 2/4;
    grid-row: 4;
  }

  .dashboard-step-wrap > div:nth-child(7) {
    grid-column: 1/4;
    grid-row: 5;
  }
}

@layer main-media-queries {
  @media only screen and (min-width: 700px) {
    .dashboard-step-wrap {
      width: 90%;
    }
  }
  @media only screen and (min-width: 700px) {
    .dashboard-step-wrap {
      width: 90%;
    }
  }
}

.farm-size-wrap {
  font-size: 1.1em;
  margin: 01.5em auto;
  accent-color: var(--color-1);
  gap: 3em;
}

.dashboard-card-footer {
  /* background-color: rgba(142, 147, 90, 0.123); */
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  padding: 0.5em;
  font-size: 0.8em;
}
.results-20yr-explanation > * {
  margin: 0.5em auto;
  width: 95%;
}

.results-warning-text {
  background-color: #8898aa20;
  color: #2b2d45;
  font-size: 0.9em;
  border-radius: var(--border-radius);
  padding: 0.75em;
}
.results-warning-text {
  font-size: 0.9em;
}
.results-warning-text p {
  font-size: 0.95em;
  margin: 0.5em;
}

.results-warning-text h4 {
  margin-bottom: 0.5em;
  font-weight: 600;
}

.results-warning-text h4 span {
  color: #351212 !important;
}

.resultscard-table .number.negative {
  color: red;
  font-weight: bold;
}
.resultscard-table .number.positive {
  color: blue;
  font-weight: bold;
}

.practice-name-tco2e-value {
  width: fit-content;
}
.field-size-input {
  max-width: 8em;
  width: 8em;
}
.field-size-input-cell {
  max-width: 8em;
  width: 8em;
}
@media only screen and (min-width: 700px) {
  .practice-name-tco2e-value {
    display: none;
  }
}
@media only screen and (max-width: 700px) {
  .field-size-input-cell {
    width: 50px !important;
    max-width: 50px !important;
  }
  .results-table-practice-cell {
    width: 75px !important;
    max-width: 75px !important;
  }
  .results-table-tco2e-cell {
    display: none;
  }
}
