/* ============ Site Colors (Primary, secondary,etc) ============= */

@layer basiccolors, utilitycolors, secondarycolors, primarycolors, icons, colorcorrection;

:root {
  --footer-color-background: hsl(var(--color-1-hsl, 0 100% 50%) / 60%);
}

@layer primarycolors {
  :root {
    --red: 1px red solid;
    --color-1-example: hsla(60 24% 32%);
    --color-1-example: #4f605b;
    --color-1-hsl: 60 24% 32%;
    --color-1-hue: 60;
    --color-1-saturation: 24%;
    --color-1-lightness: 32%;

    --color-1: hsl(
      var(--color-1-hue),
      var(--color-1-saturation),
      calc(var(--color-1-lightness) * 1)
    );
    --color-1-light: hsl(
      var(--color-1-hue),
      var(--color-1-saturation),
      calc(var(--color-1-lightness) * 2.8)
    );
    --color-1-dark: hsl(
      var(--color-1-hue),
      var(--color-1-saturation),
      calc(var(--color-1-lightness) * 0.5)
    );

    --color-1-100: hsl(
      var(--color-1-hue),
      var(--color-1-saturation),
      calc(var(--color-1-lightness) * 2.5)
    );
    --color-1-200: hsl(
      var(--color-1-hue),
      var(--color-1-saturation),
      calc(var(--color-1-lightness) * 2)
    );
    --color-1-300: hsl(
      var(--color-1-hue),
      var(--color-1-saturation),
      calc(var(--color-1-lightness) * 1.5)
    );
    --color-1-400: hsl(
      var(--color-1-hue),
      var(--color-1-saturation),
      calc(var(--color-1-lightness) * 1.2)
    );
    --color-1-500: hsl(
      var(--color-1-hue),
      var(--color-1-saturation),
      calc(var(--color-1-lightness) * 1)
    );
    --color-1-600: hsl(
      var(--color-1-hue),
      var(--color-1-saturation),
      calc(var(--color-1-lightness) * 0.9)
    );
    --color-1-700: hsl(
      var(--color-1-hue),
      var(--color-1-saturation),
      calc(var(--color-1-lightness) * 0.8)
    );
    --color-1-800: hsl(
      var(--color-1-hue),
      var(--color-1-saturation),
      calc(var(--color-1-lightness) * 0.7)
    );
    --color-1-900: hsl(
      var(--color-1-hue),
      var(--color-1-saturation),
      calc(var(--color-1-lightness) * 0.6)
    );

    --color-1-10: hsl(var(--color-1-hsl) / 0.1);
    --color-1-25: hsl(var(--color-1-hsl) / 0.25);
    --color-1-50: hsl(var(--color-1-hsl) / 0.5);
    --color-1-75: hsl(var(--color-1-hsl) / 0.75);
    --color-1-90: hsl(var(--color-1-hsl) / 0.9);
  }
}

@layer secondarycolors {
  :root {
    --color-2-hsl: 196 64% 24%;
    --color-2-hue: 196;
    --color-2-lightness: 64%;
    --color-2-saturation: 24%;
    --color-2: hsl(196 64% 24%);
    --color-2-light: hsla(196, 64%, 24%, 0.477);

    --color-2-100: hsl(
      var(--color-2-hue),
      var(--color-2-saturation),
      calc(var(--color-2-lightness) * 2.5)
    );
    --color-2-200: hsl(
      var(--color-2-hue),
      var(--color-2-saturation),
      calc(var(--color-2-lightness) * 2)
    );
    --color-2-300: hsl(
      var(--color-2-hue),
      var(--color-2-saturation),
      calc(var(--color-2-lightness) * 1.5)
    );
    --color-2-400: hsl(
      var(--color-2-hue),
      var(--color-2-saturation),
      calc(var(--color-2-lightness) * 1.2)
    );
    --color-2-500: hsl(
      var(--color-2-hue),
      var(--color-2-saturation),
      calc(var(--color-2-lightness) * 1)
    );
    --color-2-600: hsl(
      var(--color-2-hue),
      var(--color-2-saturation),
      calc(var(--color-2-lightness) * 0.9)
    );
    --color-2-700: hsl(
      var(--color-2-hue),
      var(--color-2-saturation),
      calc(var(--color-2-lightness) * 0.8)
    );
    --color-2-800: hsl(
      var(--color-2-hue),
      var(--color-2-saturation),
      calc(var(--color-2-lightness) * 0.7)
    );
    --color-2-900: hsl(
      var(--color-2-hue),
      var(--color-2-saturation),
      calc(var(--color-2-lightness) * 0.6)
    );

    --color-2-100: hsl(196 64% calc(24% * 3.5));
    --color-2-200: hsl(196 64% calc(24% * 3));
    --color-2-300: hsl(196 64% calc(24% * 2.2));
    --color-2-400: hsl(196 64% calc(24% * 1.5));
    --color-2-500: hsl(196 64% calc(24%));
    --color-2-600: hsl(196 64% calc(24% * 0.8));
    --color-2-700: hsl(196 64% calc(24% * 0.6));
    --color-2-800: hsl(196 64% calc(24% * 0.4));
    --color-2-900: hsl(196 64% calc(24% * 0.2));

    --color-2-10: hsl(var(--color-2-hsl) / 0.1);
    --color-2-25: hsl(var(--color-2-hsl) / 0.25);
    --color-2-50: hsl(var(--color-2-hsl) / 0.5);
    --color-2-75: hsl(var(--color-2-hsl) / 0.75);
    --color-2-90: hsl(var(--color-2-hsl) / 0.9);
  }
}

@layer utilitycolors {
  :root {
    --cancel-color: hsl(0, 50%, 25%);
    --diabled-color-light: #ddd;
    --mask-color-txt: 180 20% 85%;
    --mask-color: hsl(var(--mask-color-txt) / 0.7);

    --positive-number-color: hsl(240, 100%, 50%);
    --negative-number-color: hsl(0, 100%, 35%);
  }
}

@layer basiccolors {
  /* ======= BASIC COLORS ========== */
  :root {
    --light-color-hsl: 0 0% 98%;
    --light-color-10: hsl(var(--light-color-hsl) / 0.1);
    --light-color-20: hsl(var(--light-color-hsl) / 0.2);
    --light-color-30: hsl(var(--light-color-hsl) / 0.3);
    --light-color-40: hsl(var(--light-color-hsl) / 0.4);
    --light-color-50: hsl(var(--light-color-hsl) / 0.5);
    --light-color-60: hsl(var(--light-color-hsl) / 0.6);
    --light-color-70: hsl(var(--light-color-hsl) / 0.7);
    --light-color-80: hsl(var(--light-color-hsl) / 0.8);
    --light-color-90: hsl(var(--light-color-hsl) / 0.9);

    --slate-50-txt: 210 40% 98%;
    --slate-100-txt: 210 40% 96%;
    --slate-200-txt: 214 32% 91%;
    --slate-300-txt: 213 27% 84%;
    --slate-400-txt: 215 20% 65%;
    --slate-500-txt: 215 16% 47%;
    --slate-600-txt: 215 19% 35%;
    --slate-700-txt: 215 25% 27%;
    --slate-800-txt: 217 33% 17%;
    --slate-900-txt: 222 47% 11%;

    --slate-50: hsl(var(--slate-50-txt, 210 40% 98%));
    --slate-100: hsl(var(--slate-100-txt, 210 40% 96%));
    --slate-200: hsl(var(--slate-200-txt, 214 32% 91%));
    --slate-300: hsl(var(--slate-300-txt, 213 27% 84%));
    --slate-400: hsl(var(--slate-400-txt, 215 20% 65%));
    --slate-500: hsl(var(--slate-500-txt, 215 16% 47%));
    --slate-600: hsl(var(--slate-600-txt, 215 19% 35%));
    --slate-700: hsl(var(--slate-700-txt, 215 25% 27%));
    --slate-800: hsl(var(--slate-800-txt, 217 33% 17%));
    --slate-900: hsl(var(--slate-900-txt, 222 47% 11%));
    --slate: hsl(221, 26%, 19%);

    --cyan-txt: 196 64% 24%;
    --cyan-50-txt: 183 100% 96%;
    --cyan-100-txt: 185 96% 90%;
    --cyan-200-txt: 186 94% 82%;
    --cyan-300-txt: 187 92% 69%;
    --cyan-400-txt: 188 86% 53%;
    --cyan-500-txt: 189 94% 43%;
    --cyan-600-txt: 192 91% 36%;
    --cyan-700-txt: 193 82% 31%;
    --cyan-800-txt: 194 70% 27%;
    --cyan-900-txt: 196 64% 24%;

    --cyan: hsl(var(--cyan-txt, 196 64% 24%));
    --cyan-50: hsl(var(--cyan-50-txt, 183 100% 96%));
    --cyan-100: hsl(var(--cyan-100-txt, 185 96% 90%));
    --cyan-200: hsl(var(--cyan-200-txt, 186 94% 82%));
    --cyan-300: hsl(var(--cyan-300-txt, 187 92% 69%));
    --cyan-400: hsl(var(--cyan-400-txt, 188 86% 53%));
    --cyan-500: hsl(var(--cyan-500-txt, 189 94% 43%));
    --cyan-600: hsl(var(--cyan-600-txt, 192 91% 36%));
    --cyan-700: hsl(var(--cyan-700-txt, 193 82% 31%));
    --cyan-800: hsl(var(--cyan-800-txt, 194 70% 27%));
    --cyan-900: hsl(var(--cyan-900-txt, 196 64% 24%));

    --lime-txt: 88 61% 20%;
    --lime-txt: 86 69% 23%;
    --lime-50-txt: 78 92% 95%;
    --lime-100-txt: 80 89% 89%;
    --lime-200-txt: 81 88% 80%;
    --lime-300-txt: 82 85% 67%;
    --lime-400-txt: 83 78% 55%;
    --lime-500-txt: 84 81% 44%;
    --lime-600-txt: 85 85% 35%;
    --lime-700-txt: 86 78% 27%;
    --lime-800-txt: 86 69% 23%;
    --lime-900-txt: 88 61% 20%;

    --lime-50: hsl(78, 92%, 95%);
    --lime-100: hsl(80, 89%, 89%);
    --lime-200: hsl(81, 88%, 80%);
    --lime-300: hsl(82, 85%, 67%);
    --lime-400: hsl(83, 78%, 55%);
    --lime-500: hsl(84, 81%, 44%);
    --lime-600: hsl(85, 85%, 35%);
    --lime-700: hsl(86, 78%, 27%);
    --lime-800: hsl(86, 69%, 23%);
    --lime-900: hsl(88, 61%, 20%);
    --lime-500: #a5a14b;
  }
}

@layer colorcorrection {
  /**
   * this is html to show examples of colorcorrections
   * 
<div>
  <div className="ax-emo-example">
    <div>-:</div>
    <div className="ax-color-norm2red">ax-color-norm2red:</div>
    <div className="ax-color-90">ax-color-90:</div>
    <div className="ax-color-nooutline">ax-color-nooutline:</div>
    <div className="ax-color-black">ax-color-idk:</div>
    <div className="ax-color-eh">ax-color-eh:</div>
  </div>
  <span>Change the code point to text mode:</span>
  <div className="ax-mono-example">
    <div className="a">a:</div>
    <div className="ax-color-invert">ma:</div>
  </div>
</div>

   */
  .ax-color-norm2red {
    /* Normalize colour to a primary red */
    filter: sepia(1) saturate(100);
  }
  .ax-color-90 {
    /* Less saturation so more features, shifted colour 90-degrees */
    filter: sepia(1) saturate(5) hue-rotate(90deg);
  }
  .ax-color-nooutline {
    /* Remove black outlines if desired by removing contrast */
    filter: contrast(0) sepia(1) saturate(100) hue-rotate(180deg);
  }
  .ax-color-black {
    /* Other shades possible by lowering brightness */
    filter: contrast(0) sepia(1) saturate(100) brightness(0.05) hue-rotate(180deg);
  }
  .ax-color-eh {
    /* Weird possibilities with no colour normalization */
    filter: contrast(100) hue-rotate(180deg);
  }
  .ax-color-invert {
    /* Invert to provide a gray shade to apply sepia*/
    filter: invert(0.5) sepia(1) saturate(100);
  }
  .ax-emo-example > div::after {
    content: '🌍⛄😍🐱';
  }
  .ax-mono-example > div::after {
    content: '\1F30D\FE0E\26C4\FE0E\1F60D\FE0E\1F431\FE0E';
  }
}

@layer icons {
  span.icon.filter::after,
  span.icon.filter::before {
    font-family: monospace !important;
    position: relative;
    font-size: 1.5rem !important;
    filter: contrast(0) sepia(1) hue-rotate(20deg) saturate(204%) brightness(40%);
    filter: contrast(0) sepia(1) hue-rotate(20deg) saturate(204%) brightness(100%);
  }
  .icon.paperclips::before {
    content: '\1F587';
  }
  .icon.paperclip::before {
    content: '\1F4CE';
  }
  .icon.info::before {
    content: '\1F587';
    content: '\1F6C8';
    content: '\2139';
  }
  .icon.tool::before {
    content: '\1F6E0';
  }
  .icon.hammers::before {
    padding: 0.25em 0em;
    content: '\2692';
    font-size: 2em;
  }
  .icon.arrowhead::before {
    content: '\27A4';
  }
  .icon.warning::before {
    content: '\26A0';
  }
  .icon.noentry::before {
    content: '\26D4';
  }
  .icon.skiier::before {
    content: '\26F7';
  }
  .icon.lowrightpencil::before {
    content: '\270E';
  }
  .icon.bier::before {
    content: '\1F37A';
  }
  .icon.wrench::before {
    content: '\1F527';
  }
  .icon.hammerwrench::before {
    content: '\1F6E0';
  }
  .icon.questionmark::before {
    content: '\128736';
  }
  .icon.x::before {
    content: '\274C';
    filter: hue-rotate(180deg) brightness(0.31) saturate(0.51);
  }
  .icon.minus-circle::before {
    content: '\2296';
  }
  .icon.plus-circle::before {
    content: '\2a01';
    content: '\2295';
  }
  .icon.plus::before {
    content: '\ff0b';
  }
  .icon.minus::before {
    content: '\FE63';
  }
  .icon.minus-circle::before {
    content: '\2296';
  }
  .icon.plus-circle::before {
    content: '\2a01';
    content: '\2295';
    content: '\ff0b';
  }
  .icon.minus-circle::before {
    content: '\FE63';
  }
  .icon.home::before {
    content: '\1f3e0';
    font-size: 0.8em !important;
  }
  .icon.hometree::before {
    content: '\1f3e1';
    font-size: 1.2em !important;
    filter: contrast(0) sepia(1) hue-rotate(60deg) saturate(1) brightness(0.624);
  }
  .icon.house::before {
    content: '\2302';
    font-size: 1.2em !important;
  }
  .icon.trash::before {
    content: '\1F5D1';
  }
  .icon.copy::before {
    content: '🗐';
  }
  .icon.graph::before {
    content: '🗠';
  }
  .icon.arrowrightdown::before {
    content: '\2198';
  }
  .icon.arrowleft::before {
    content: '\2190';
    font-size: 1.5em;
  }
  .icon.arrowleft.fancy::before {
    content: '\219c';
    font-size: 1.5em;
    margin-right: 0.25em;
  }
  .icon.arrowleft.large::before {
    content: '\21e6';
    margin-right: 0.25em;
  }
  .icon.arrowright::before {
    content: '\2192';
    color: red;
  }
  .icon.back-btn::before {
    content: '\2190';
    color: red;
    position: absolute;
    translate: -0.65em -0.342em;
    font-size: 3em !important;
  }
  .icon.next-btn::after {
    content: '\2192';
    color: red;
    position: absolute;
    translate: 0.1em -0.35em;
    font-size: 3em !important;
  }
  .icon.downarrow::before {
    content: '\21E9';
    text-decoration: underline;
  }
  .icon.contact::before {
    content: '\1F4AC';
    font-size: 0.8em !important;
    filter: contrast(0) sepia(1) hue-rotate(60deg) saturate(1) brightness(0.624);
  }
  .icon.dash::before {
    content: '-';
    color: inherit !important;
    font-size: 0.8em !important;
    filter: contrast(0) sepia(1) hue-rotate(60deg) saturate(1) brightness(0.624);
  }
}
