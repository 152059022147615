.glob-detailed-sequestration-table thead th {
  text-align: center;
}
.glob-detailed-sequestration-table {
  position: relative;
}
.glob-detailed-sequestration-table tr td:first-child {
  min-width: 200px;
  white-space: unset;
}
/* Sticky does not work in chromium */
.glob-detailed-sequestration-table thead th {
  background-color: white;
  position: sticky;
  top: 0;
}
