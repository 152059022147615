.ax-header {
  background-color: var(--color-2-700);
}
.ax-header :is(h2, h1) {
  margin: 0;
  padding: 0;
  font-size: 1.3rem;
}
@media only screen and (min-width: 900px) {
  .ax-header.img {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    background-image: url('./assets/tractor_mountain.jpg');
  }
}
