.equal-width {
  table-layout: fixed;
  border-collapse: collapse;
}

.equal-width td {
  width: auto;
}

.basic-table,
.animal-table th {
  text-align: left;
}

.basic-table,
.animal-table th {
  padding: 1em;
}

.basic-table,
.animal-table th,
.basic-table,
.animal-table td {
  padding: 1em 0.5em 1em 0.5em;
}

.basic-table,
.animal-table {
  width: 100%;
  border-collapse: collapse;
}

.basic-table,
.animal-table tbody tr {
  border-bottom: 0.25px solid rgb(218, 226, 236);
}

table input[type='checkbox'] {
  height: 1em;
  width: 1em;
  display: flex;
}

.rotate-table-header th {
  rotate: -45deg;
  text-wrap: nowrap;
  height: 200px;
}

.basic-table,
td.number {
  text-align: right;
}

.animal-table td[value='--'] {
  text-align: right;
}

.basic-table td.number.zero::before {
  content: '--';
  color: #2b3644;
}

.basic-table td.number.zero {
  color: transparent;
}

.basic-table,
.animal-table td.number.negative {
  color: red;
  font-weight: bold;
}

.basic-table,
.animal-table td.number.positive {
  color: blue;
  font-weight: bold;
}

.basic-table,
.animal-table tr:last-child {
  border: none !important;
}
