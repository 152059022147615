:root {
  --cpgl-farmtype-or-padding: 0.5em 0;
}

.landuse-image {
  max-height: 100px;
  width: auto;
}
.landuse-component,
.cpgl-farmtype {
  margin: 0.4em;
  height: 100%;
  display: flex;
  align-items: center;

  gap: 0.5em;
}

.cpgl-farmtype-or {
  padding: 1.5em 0.5em;
}

.cpgl-farmtype {
  max-width: 400px;
  margin: auto;
  padding: 0.5em;
}
/* .landuse-component button, */
.cpgl-farmtype button {
  /* box-shadow: 0.005rem 0.005rem 0px 1.5px var(--color-1-50); */
  padding: 0.5em;
  /* border: none !important; */
  /* outline: none !important; */
  /* border: 1px var(--color-2) solid !important; */
}
.cpgl-farmtype button {
  height: 5rem;
}

.cpgl-farmtype button.active,
.landuse-component button.active {
  background-color: var(--color-1);
  color: white;
  color: var(--color-light);
}

.landuse-component > *,
.cpgl-farmtype > * {
  flex: 1;
}
.landuse-component button {
  border: none;
  font-weight: 500;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 750px) {
  .landuse-component {
    max-width: 98%;
  }
}

@media only screen and (min-width: 900px) {
  .cpgl-farmtype {
    flex-direction: column;
    align-items: stretch;
  }
  .cpgl-farmtype-or {
    padding: 0;
  }
}

@media only screen and (min-width: 1300px) {
  .cpgl-farmtype {
    flex-direction: row;
    align-items: center;
    padding: 1.5em 0;
  }
}
@media only screen and (min-width: 1930px) {
  .cpgl-farmtype,
  .landuse-component {
    margin: 1em auto;
    width: 96%;
  }
}

.landuse-btn,
.landuse-btn:active,
.landuse-btn:focus {
  transition: all 0ms !important;
  outline: none !important;
  border: 0.22px transparent solid !important;
  font-weight: 400 !important;
}
