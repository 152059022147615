.branding-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  /* margin: 0 0 0 1em; */

  padding: 1em;
}

.branding-container.header-child {
  display: none;
}
@media only screen and (min-width: 1100px) {
  .branding-container:is(.header-child, .footer-child) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
  }
}
