:root {
  --box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
  /* --color-1: #4f605b; */
  --color-1: #aec6be;
  --color-1: #677c75;
  --color-light: #efefef;
  --breakpoint-1: 1200px;
  --cancel-color: #683535;
  --max-width-prose: 900px;
  --bg-information: #f0f2f4;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.global-step-title::before {
  counter-increment: section; /* Increment the value of section counter by 1 */
  content: counter(section); /* Display counter value in default style (decimal) */
  border-radius: 50%;
  padding: 0.5em;
  margin-right: 0.5em;
}

html {
  scroll-behavior: smooth !important;
}

#root {
  /* background-color: rgb(207, 207, 207); */
  background-color: #e1e1e1f0;
}

h2 {
  font-size: 1.2rem;
  color: inherit;
  text-transform: uppercase;
  text-transform: capitalize;
}
h3 {
  font-size: 0.8rem;
  color: inherit;
  text-transform: uppercase;
  text-transform: capitalize;
}

ul,
li {
  padding: 0;
  margin: 0;
}

summary {
  padding-left: 0.5em;
}

.prose {
  max-width: var(--max-width-prose);
  margin: auto;
}
