.map-container {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: var(--border-radius);
  overflow: hidden;
  --tile-card-padding: 0.5em;
  --tile-card-bg: #dbe8ed;
  --tile-card-bg: #f5f5f5;
  --map-blank-bg-color: #e3ebfc;
  --tile-blank-background-color: var(--map-blank-bg-color, #fefefe);
  --tile-blank-background-color: var(--tile-card-bg, #fefefe);
  --map-key-item-length: 1rem;
  --shadow-md: 2px 2px 5px rgba(54, 64, 74, 0.333);
  background-color: var(--map-blank-bg-color);
}
.map-upper-left-controls {
  /* absolute top-3 left-10 flex items-center gap-2 */
  position: absolute;
  top: 11px;
  left: 40px;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.map-upper-right-controls {
  user-select: none;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  display: flex;
  flex-direction: column;
  height: 87%;
  /* border: red solid 1px; */
}

.map-upper-right-controls > *:last-child {
  flex-grow: 1;
  overflow: auto;
  height: 100%;
  background-color: transparent;
}

.map-interaction-card {
  background-color: var(--tile-card-bg);

  border-radius: 5px;
  box-shadow: var(--shadow-md);
  margin-bottom: 0.3rem;
  padding: var(--tile-card-padding);
}

details.map-interaction-layers-wrapper summary {
  font-size: 1.2em;
  font-weight: 400;
  display: flex;
}
details.map-interaction-layers-wrapper summary > *:first-child {
  flex-grow: 1;
}
details.map-interaction-layers-wrapper summary > .map-layer-x-icon {
  display: none;
}
details.map-interaction-layers-wrapper[open] summary > .map-layer-x-icon {
  display: inline-block;
}

details.map-interaction-layers-wrapper summary > span .map-layers-open-txt {
  display: none;
}
details.map-interaction-layers-wrapper summary > span .map-layers-closed-txt {
  display: inline-block;
}
details.map-interaction-layers-wrapper[open] summary > span .map-layers-closed-txt {
  display: none !important;
}
details.map-interaction-layers-wrapper[open] summary > span .map-layers-open-txt {
  display: inline-block;
}

details.map-interaction-layers-wrapper-secondary .map-layer-summary-icon {
  transition: all 200ms;
  rotate: -90deg;
}
details.map-interaction-layers-wrapper-secondary[open] .map-layer-summary-icon {
  rotate: 0deg;
}

details.details-open-close-arrow summary {
  display: flex;
  align-items: center;
  gap: 0.25em;
}
details.details-open-close-arrow summary > .summary-open-close-arrow::after {
  content: '▸';
}
details.details-open-close-arrow[open] summary > .summary-open-close-arrow::after {
  content: '▾';
}

.map-recenter-icon {
  background-color: var(--tile-card-bg);
  box-shadow: var(--shadow-md);
}
.map-recenter-icon:hover {
  background-color: rgb(133, 145, 152);
}

.map-searchbox > input:focus,
.map-searchbox > input:active {
  border: none;
  outline: none;
  border-bottom: slategray 0.25px solid;
}
.map-searchbox > input {
  max-width: 11em;
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: slategray 0.25px solid;
  border-radius: 0;
  padding: 0em 0.5em 0em 0em;
}
.map-searchbox {
  max-width: 250px;
  padding: 0em 0.5em 0em 01em;
  border-radius: 0.35em;
  display: flex;
  align-items: center;
  box-shadow: var(--shadow-md);
  background-color: var(--tile-card-bg);
}
.map-key-wrapper {
  background-color: var(--tile-blank-background-color);
  border-radius: 2.5px;
  display: inline-block;
  border: solid 0.5px;
  height: var(--map-key-item-length);
  width: var(--map-key-item-length);
}

.map-key-item {
  border-radius: 2.5px;
  margin-top: -0.25px;
  display: inline-block;
  height: var(--map-key-item-length);
  width: var(--map-key-item-length);
  position: relative;
  transform: translate(0.5px, -1px);
  transform: translate(-1px, -2px);
}
.map-information-popup span {
  font-weight: bold;
  transition: all 500ms;
}
.map-information-popup {
  transition: all 400ms;
  background-color: #dbe8ed;
  background-color: var(--tile-card-bg);
  box-shadow: var(--shadow-md);
  border-radius: 5px;
  padding: 0.5em;

  position: absolute;
  bottom: 38px;
  left: 10px;
}
.map-transition {
  transition: all 500ms ease-in-out;
}

@layer map-utils {
  .cursor-pointer {
    cursor: pointer;
  }
  .rounded-md {
    border-radius: 5px;
  }
  .pointer-events-none {
    pointer-events: none;
  }
  .pointer-events-auto {
    pointer-events: auto;
  }
  .flex {
    display: flex;
  }
  .items-center {
    align-items: center;
  }

  .gap-1 {
    gap: 0.15em;
  }
  .gap-2 {
    gap: 0.5em;
  }
}
