/* *, */
.classnamefortestingandnottobeintheapp {
  border: red solid 1px;
  color: #bb861c;
  color: #5393a0;
  box-sizing: border-box;
}

@import './global-colors-icons.css'; /* Using a string */

/**
* @layer explained: https://developer.mozilla.org/en-US/docs/Web/CSS/@layer
* good for basic things that can be overridden easily or have the order changed in mass.
* anything delcared outside of a layer will be compiled and dropped at the end.
* Therefore all layers have lower priority than willie nillie css.
* A useful little trick this may be.
*/

@layer atomic, base, utils, form, button, widgets, features, homepage, scratch, screensizes, cometplannerglobal;

@layer cometplannerglobal {
  /* .desktop-only {
        display: none;
    }
    @media only screen and (min-width: 900px) {
        .mobile-only {
            display: none;
        }
        .desktop-only {
            display: block;
        }
    } */
  .MuiAlert-standardInfo {
    margin: auto;
    max-width: var(--max-width-prose);
  }
  button.active {
    background-color: var(--color-1);
    color: var(--color-1-light);
    color: white;
  }
  table {
    border-radius: var(--border-radius);
    overflow: hidden;
  }

  .cpg-info-button {
    color: var(--slate-400);
  }
  .cpg-info-button:hover {
    color: var(--slate-500);
  }
  .header-banner-container em {
    font-style: normal;
    font-size: 1.14em;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input::placeholder {
    font-weight: 100;
  }
  input {
    border-radius: 3px;
  }
  input:invalid,
  input:placeholder-shown {
    background-color: rgb(238, 238, 169);
  }
  input:is(:placeholder-shown, :invalid)::placeholder {
    color: var(--slate-900, black);
  }
  input:focus {
    background-color: transparent;
  }
  input:focus::placeholder {
    color: transparent;
  }
  summary {
    padding-left: 0.25em;
    overflow: hidden;
    white-space: normal;
  }
  @media only screen and (max-width: 900px) {
    details summary {
      cursor: unset;
      list-style-type: none;
    }
    /* Firefox */
    details summary::-webkit-details-marker {
      display: none;
    } /* Chrome */
    details summary::marker {
      display: none;
    }
  }
  em {
    font-size: 1.05em;
    font-weight: 700;
  }

  thead tr:first-child > th {
    font-size: 1.01em !important;
  }

  th:nth-child(1) {
    max-width: 100px;
  }

  td,
  th {
    font-size: 1rem !important;
  }
  .resultscard-table tr > :is(th, td):nth-child(n + 2) {
    max-width: 75px;
    text-align: center !important;
    white-space: normal !important;
  }

  .append-units-hectare::after {
    content: 'ha';
    white-space: nowrap;
  }

  .resultscard-btns {
    border-bottom: 1px solid var(--color-1);
    gap: 0.05em;
    margin: 0 0.35em;
  }

  .resultscard-btns button {
    margin: 0 0.05em;
  }
}

@layer button {
  .btn-slide:has(:hover) {
    border: none;
    outline: transparent;
  }
  .btn-slide > span {
    transition: width 500ms color 500ms;
    width: 0;
    word-wrap: normal;
    color: transparent;
  }
  .btn-slide:hover > span {
    width: auto;
    color: var(--color-1);
  }
  :is(button, btn):disabled {
    opacity: 0.5;
  }
  a.link-btn:hover,
  a.link-btn:focus {
    outline: var(--color-1) solid 2px;
  }
  a.link-btn {
    color: #f0f0f0 !important;
    background-color: var(--color-2-600) !important;
    background-color: var(--color-2) !important;
    display: inline-block;
    border: 1px solid var(--color-1);
    background-color: inherit;
    padding: 0em 0.5em;
    text-decoration: none !important;
    font-weight: bold;
    border-radius: var(--border-radius);
    cursor: pointer;
  }
}

@layer form {
  :root {
    --button-transition: background-color 250ms, border 250ms, font-weight 150ms;
    --input-padding: 0.25em;
  }

  .ax-input > .passwordWrap:focus-within {
    outline: var(--color-2-100) solid 2px;
    background-color: var(--color-1-light);
  }
  .ax-input > .passwordWrap > input:focus {
    background-color: var(--color-1-light);
    outline: none;
    border: none;
  }

  :is(input, textarea, button, select, .passwordWrap button):focus-visible {
    outline: transparent;
    outline: var(--color-1-900) solid 1px;
    color: hsl(var(--color-1-hsl) / 1);
    background-color: var(--slate-100);
    font-weight: 900;
  }
  :is(input, textarea, button, select, .passwordWrap) {
    background-color: transparent;
    font: inherit;
    box-sizing: border-box;
    border: var(--primary-border);
    padding: var(--input-padding);
    border-radius: var(--input-border-radius);
  }
  .passwordWrap {
    display: inline-block;
    padding: 0;
  }

  .ax-input > input {
    margin: 0.25em 0;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: min(500px, 100%);
    margin: auto;
  }
  :is(input[type='submit'], button) {
    transition: var(--button-transition);
    font-weight: bold;
    cursor: pointer;
    box-sizing: border-box;
    padding: 0.5em;
    border-radius: 0.3em;

    color: var(--color-1);
    border: var(--color-1) 1px solid;
    background-color: transparent;
  }

  :is(button, input[type='submit']).btn-1-light:is(:focus, :hover) {
    outline: 2px var(--color-2-200) solid;
    outline: 2px solid var(--light-color, inherit);
  }

  input[type='submit'].btn-1-light,
  button.btn-1-light {
    background-color: var(--color-1-light);
    border: var(--color-1) 1px solid;
    color: var(--color-1, inherit);
  }

  input[type='submit']:hover,
  button:hover,
  input[type='submit']:focus,
  button:focus,
  a.link-btn:focus,
  a.link-btn:hover {
    box-sizing: border-box;
    transition: var(--button-transition);
    font-weight: bold;
    background-color: var(--slate-200);
  }
  input[type='submit']:focus,
  button:focus {
    border: var(--color-1) 1px solid !important;
    outline: 1px var(--color-1-500) solid !important;
  }
  input[type='submit'],
  button {
    /* REMOVED 20240830_ delete if unneeded border: transparent 1px solid !important; */
    outline: 1px transparent solid !important;
    outline: transparent;
  }

  input[type='checkbox'] {
    accent-color: var(--color-2-200, green);
    accent-color: var(--color-2-200);
  }

  button.ax-link {
    background-color: transparent;
    color: var(--lime-500);
    color: inherit;
    font-weight: normal;
    padding: 0;
    margin: 0;
    border: none;
  }
  button.ax-link:hover,
  button.ax-link:focus {
    text-decoration: underline;
    color: var(--color-1-400);
    color: var(--lime-500);
    outline: hsl(var(--color-1-hsl, 0 10% 200%) / 0.591) solid 3px;
  }
  button.ax-unset {
    all: unset;
  }

  button.btn-clear {
    border: none;
  }
  button.btn-clear:focus {
    border: none;
    color: inherit;
    background-color: inherit;
  }
}

@layer screensizes {
  .hidden {
    display: none;
  }
  .flex {
    display: flex;
  }
  .block {
    display: block;
  }
  .inline {
    display: inline;
  }

  @media only screen and (min-width: 300px) {
    .xs-hidden {
      display: none;
    }
    .xs-flex {
      display: flex;
    }
    .xs-block {
      display: block;
    }
    .xs-inline {
      display: inline;
    }
  }

  @media only screen and (min-width: 600px) {
    .sm-hidden {
      display: none;
    }
    .sm-flex {
      display: flex;
    }
    .sm-block {
      display: block;
    }
    .sm-inline {
      display: inline;
    }
  }

  @media only screen and (min-width: 900px) {
    .md-hidden {
      display: none;
    }
    .md-flex {
      display: flex;
    }
    .md-block {
      display: block;
    }
    .md-inline {
      display: inline;
    }
  }

  @media only screen and (min-width: 1080px) {
    .lg-hidden {
      display: none;
    }
    .lg-flex {
      display: flex;
    }
    .lg-block {
      display: block;
    }
    .lg-inline {
      display: inline;
    }
  }

  @media only screen and (min-width: 1200px) {
    .xl2-hidden {
      display: none;
    }
    .xl2-flex {
      display: flex;
    }
    .xl2-block {
      display: block;
    }
    .xl2-inline {
      display: inline;
    }
  }
}

@layer utils {
  details.no-marker > summary {
    list-style: none;
  }
  details.no-marker > summary::marker {
    list-style: none;
  }
  details.no-marker > summary::-webkit-details-marker {
    display: none;
  }
  ul.dashed {
    margin: 0;
  }
  ul.dashed {
    list-style-type: none;
  }
  ul.dashed > li {
    text-indent: -0.5em;
  }
  ul.dashed > li:before {
    content: '- ';
    text-indent: -0.5em;
  }
  .ax-asterisk-before::before {
    content: '*';
  }
  .ax-asterisk-before.double::before {
    content: '**';
  }
  .ax-children.float-right > * {
    float: right;
  }
  progress {
    --progress-color: hsl(215, 50%, 50%);
    --progress-background-color: hsl(0, 0%, 73%);
  }
  progress[value]::-webkit-progress-bar {
    background-color: var(--progress-background-color);
  }
  progress[value] {
    -webkit-appearance: none;
  }
  progress[value]::-webkit-progress-value {
    background-color: var(--progress-color);
  }
  progress::-moz-progress-bar {
    background: var(--progress-color);
  }
  progress::-webkit-progress-value {
    background: var(--progress-color);
  }
  .cg-progress {
    position: relative;
    width: 100%;
    height: 0.4em;
    border-radius: var(--border-radius);
    overflow: hidden;
  }
  .cg-progress::before {
    /* content: "\00a0 \a Zoom Level"; */
    position: absolute;
    color: var(--slate-100);
  }

  .ax-unselectable {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
  }
  .ax-message-inline {
    background-color: var(--color-1-10);
    padding: 1em;
    margin: 0.5em;
    border-radius: 0.25em;
    border-radius: 0.325em;
  }

  .box-shadow-oblivion {
    box-shadow: 0 1px 5px 100vw #00000000;
    box-shadow: 0 1px 5px 200vw #ffffff88;
    overflow: hidden;
  }

  .ax-arrow.right {
    translate: 0.5em 0.1em;
  }
  .ax-arrow.up {
    rotate: -90deg;
    transform: rotateX(180deg);
    transform: rotateX(-180deg);
    translate: 0.25em -0.1em;
  }
  .ax-arrow.down {
    rotate: 90deg;
    width: 1rem;
    translate: 0.25em 0.25em;
  }
  .ax-arrow::after {
    content: 'M';
    font-size: 0.8em;
    color: transparent !important;
    text-decoration: none !important;
    margin-right: 0.3em;
    mask: url('./assets/triangle-right-icon.svg') no-repeat left center;
    background-color: var(--color-light);
  }

  .ax-float-left {
    float: left;
  }
  .ax-float.right,
  .ax-float-right {
    float: right;
  }

  .ax-float.left:after,
  .ax-float.right:after,
  .ax-float-left:after,
  .ax-float-right:after,
  .clear-me-floats:after {
    content: '';
    position: relative;
    display: block;
    clear: both;
  }

  .ax-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5em;
  }

  :is(.ax-flex, .ax-flex-0, .ax-flex-2) {
    display: flex;
    gap: 1em;
    align-items: center;
  }
  :is(.ax-flex, .ax-flex-0, .ax-flex-2).wrap {
    flex-wrap: wrap;
  }

  :is(.ax-flex, .ax-flex-0, .ax-flex-1).grow > * {
    flex-grow: 1;
  }

  .ax-flex.half,
  .ax-flex-half-gap {
    gap: 0.5em;
  }

  .ax-flex-0,
  .ax-flex.no-gap {
    gap: 0em;
  }

  .ax-flex-2,
  .ax-flex.two {
    gap: 2em !important;
  }

  .ax-flex-grow,
  .ax-flex.grow {
    flex-grow: 1;
  }
  .ax-flex.children-grow > * {
    flex-grow: 1;
  }
  .ax-cursor-pointer {
    cursor: pointer;
  }
  .ax-flex.column,
  .ax-flex-0.column {
    flex-direction: column;
  }
  .ax-flex.column > *,
  .ax-flex-0.column > * {
    width: 100%;
  }
  .ax-flex.justify-center {
    justify-content: center;
  }
  .cursor-progress {
    cursor: progress;
  }

  .input-width {
    width: clamp(300px, 98%, 600px);
    margin: auto;
  }

  .ax-circle {
    background-color: var(--color-2);
    border-radius: 50%;
    color: white;
    width: 1.8em;
    height: 1.68em;
    display: grid;
    place-items: center;
    float: left;
  }
  .show-hide-maintain-space {
    opacity: 0;
    pointer-events: none;
    transition: opacity 300ms;
  }
  .show-hide-maintain-space.show {
    transition: opacity 300ms;
    opacity: 1;
    pointer-events: all;
  }
  .show-hide-height {
    height: 0;
    pointer-events: none;
    transition: height 1000ms;
    overflow: hidden;
  }
  .show-hide-height.show {
    transition: height 1000ms;
    height: 1000px;
    pointer-events: all;
  }
}

@layer base {
  :root {
    --primary-border: var(--color-1) solid 1px;
    --secondary-border: var(--secondary-color) solid 1px;
    --primary-border05: var(--color-1-05) solid 1px;
    --primary-border50: var(--color-1-50) solid 1px;
    --primary-border-600: var(--color-1-600) solid 1px;
    --primary-border30: var(--color-1-30) solid 1px;
    --primary-border40: var(--color-1-40) solid 1px;
    --primary-border25: var(--color-1-25) solid 1px;
    --primary-border20: var(--color-1-20) solid 1px;

    --max-page-width: min(90vw, 1920px);
    --max-main-width: 50%;
    --maxPageWidthParagraph: 1130px;
    --maxPageWidthInner: 1600px;
    --headerHeight: 75px;
    --fontSizeHeader: 2.5vh;
    --leftSideBarClosedWidth: 75px;
    --mobile-only-width: 1900px;
    --padding-default: 1em;
    --box-shadow-oblivion: 0 1px 5px 200vw #ffffff88;

    --scrollBarWidth: 1rem;
    --border-radius: 5px;
  }

  a:hover,
  a:focus {
    color: purple;
    color: var(--lime-500);
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }
  h1 {
    color: hsl(217 33% 17%);
    color: var(--slate);
    color: var(--slate-800);
    color: var(--slate-700);
  }
  h2,
  h3 {
    color: hsl(215 25% 27%);
    color: var(--slate-700);
    color: var(--slate-800);
  }

  main a {
    text-decoration: underline;
  }
  main {
    margin: auto;
  }

  summary {
    cursor: pointer;
  }

  .primary-border {
    border: var(--primary-border, #ddd solid 1px);
    border-radius: var(--border-radius, 0.25em);
  }
  .primary-border-2 {
    border: var(--color-1) 2px solid;
    border-radius: var(--border-radius);
  }

  .desktop-only {
    display: none;
  }
  @media only screen and (min-width: 900px) {
    .mobile-only {
      display: none !important;
    }
    .desktop-only {
      display: unset;
    }
  }

  @media only screen and (max-width: 900px) {
    .expendable {
      display: none !important;
    }
  }
}

.redbox {
  border: red solid 1px;
}

/*without the arrow buttons the numbers in the area fields look off-center*/
/*not sure where the best place is to put this rule*/
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}
