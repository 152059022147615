.stack-span {
  background-color: var(--tile-card-bg);
}
.stack-span-stacked {
  right: 0.5rem !important;
  bottom: 0.5rem !important;
  box-shadow: none;
}

.map-img-satellite {
  background-image: url(../map.assets/satelliteview.png);
}
.map-img-street {
  background-image: url(../map.assets/streetview.png);
}
.map-img-blank {
  background-color: var(--tile-blank-background-color);
  background-color: white;
}
.map-img-unterries {
  background-image: url(../map.assets/unterries.png);
}
.map-img {
  object-fit: contain;
  border-radius: 5px;
  display: inline-block;
  height: 1rem;
  width: 1rem;
  border: 01.25px solid #8ea1b5;
  box-shadow: var(--shadow-md);
}

#map-base-layer-expand-btn {
  border-radius: 50%;
}
.stack-span-stacked ~ #map-base-layer-expand-btn {
  border-radius: inherit;
}
#map-base-layer-expand-btn::before {
  content: '❯';
  display: inline-block;
  border-radius: inherit;
  border-radius: 5px;
  color: white;
  background-color: #4b5762;
  border: #8ea1b5 1px solid;
  font-size: 0.8rem;
  padding: 0.1em 0.5em;
  transition: all 300ms ease-in-out;
}
.stack-span-stacked ~ #map-base-layer-expand-btn::before {
  transform: rotate(180deg);
  border-radius: 50%;
  font-size: 0.7rem;
  padding: 0.1em 0.6em;
}

#map-base-layer-expand-btn.dark-layer::before {
  background-color: white;
  color: #2c3d4e;
}

/* 
- this moves Bings attribution b/c the base layer interactions was covering it.
- may need to make target more specific if more maps are added.
*/
.ol-attribution {
  direction: rtl;
  left: 150px;
  left: 0.5em;
  right: unset;
  z-index: 1;
}

.ol-scale-line {
  left: 3em;
}

.map-img-opacity-50 {
  opacity: 0.5;
}
