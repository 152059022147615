.ax-iconbtns-container {
  height: 2em;
  align-items: center;
  margin-top: 2em;
}

.ax-iconbtns-child-wrap {
  text-align: center;
  display: flex;
  align-items: center;
}
.ax-axuserinput-axbtniconstretch {
  padding: 0.25em 0.25em;
  border: none;
  border-radius: 5px;
}

.ax-axuserinput-axbtniconstretch i {
  offset: 10px 10px;
  margin: 0 0.25em;
  vertical-align: middle;
  font-size: 1.4em;
}

.ax-axuserinput-axbtniconstretch label {
  font-size: 1rem;
  max-width: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
}

.ax-axuserinput-axbtniconstretch:is(:hover, :focus) {
  background-color: unset;
}
.ax-axuserinput-axbtniconstretch:is(:hover, :focus) label {
  margin-right: 0.5em;
  transition:
    max-width 700ms ease-out 700ms,
    margin-right 300ms ease-out 700ms;
  max-width: 20rem;
}
.ax-axuserinput-axbtniconstretch.cdfa-pdf-download:not(:disabled) label {
  max-width: 20rem;
}

.ax-iconbtns-transition {
  color: inherit;
}

.ax-iconbtns-transition:disabled {
  color: grey !important;
  cursor: not-allowed !important;
}
